document.addEventListener('DOMContentLoaded', function () {

	// make sure only runs on individual device page
	if (document.querySelector('.page-devices-edit_all') == null) {
		return;
	}

	setUpEditAllDevices()

	new Tablesort(document.getElementById('edit-devices-editable-table-form'));

}, false);

const setUpEditAllDevices = function () {
	let editableFields = document.querySelectorAll("td[contenteditable]")
	editableFields.forEach((field) => {
		field.addEventListener("keydown", e => {
			if (e.keyCode === 13) {
				e.preventDefault();
				console.log("The enter key has been disabled. Press one of the buttons on the right")
			}
		});
	});
}

const enableAddButton = function () { enableButton("add-new-device-button", "Add") }

const enableButtonByType = function(deviceId, request_type) {
	if (request_type === 'post')
		enableButton("add-new-device-button", "Add")
	else if (request_type === 'patch')
		enableButton(request_type + "-button-" + deviceId, "Save")
	else if (request_type === 'delete')
		enableButton(request_type + "-button-" + deviceId, "Delete")
}

const enableButton = function (id, buttonText) {
	setTimeout(()=> {
		let addButton = document.getElementById(id)
		if (addButton) {
			addButton.disabled = false;
			addButton.innerText = buttonText;
		}
	},150)
}

editAllDevices = function (deviceId, request_type) {
	if (!request_type || !deviceId) return

	let updatedFields = document.querySelectorAll("#device-row-" + deviceId + " td:not(.row-submit)")
	let updateParams = {}
	let confirmMsgChanges = ""
	let deviceName = ""
	let numOfChangesMade = 0
	let userInput

	updatedFields.forEach((tableField) => {
		let value = tableField.innerText;
		let originalValue = tableField.dataset.originalValue !== undefined
			? tableField.dataset.originalValue : "";
		let field = tableField.dataset.field

		if (value !== originalValue && request_type !== 'delete') {
			numOfChangesMade++
			confirmMsgChanges += "    " + field + ": '" + originalValue + "' => '" + value + "'\n";

			updateParams[field] = value;
		}

		if (field === 'name') {
			deviceName = originalValue;
		}

	})

	if (request_type === 'delete') {
		confirmMsgChanges += "    You are deleting device ID: " + deviceId + "\n";
		userInput = prompt("Changes made for " + deviceName +
			":\n\n" + confirmMsgChanges + "\nIf you are sure, Type in \"DELETE\" (all caps) below:");
		if (userInput !== "DELETE") {
			if (userInput !== null) nbAlert("You must enter \"DELETE\" into the field to proceed. Try again.")
			enableButtonByType(deviceId, request_type);
			return;
		}
		else userInput = true;
	} else if (numOfChangesMade === 0) {
		nbAlert('No changes have been made.', 3000)
		enableButtonByType(deviceId, request_type);
		return;
	} else if (request_type === 'post') {
		userInput = confirm("Are you sure you want to add the new " +
			"device as is? Once submitted some changes are not able to be made.");
	} else {
		userInput = confirm("Changes made for " + deviceName + ":\n\n" + confirmMsgChanges
			+ "\nAre you sure?");
	}

	if (userInput !== true) {
		enableButtonByType(deviceId, request_type);
		return;
	}

	let myHeaders = new Headers();
	myHeaders.append("Authorization", "Basic Og==");
	myHeaders.append("Content-Type", "application/json");

	let raw = JSON.stringify({updateParams});

	let requestOptions = {
		method: request_type.toUpperCase(),
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	fetch("/api/v1/devices/" + deviceId, requestOptions)
		.then(response => {
			let returned_status = response.status;
			response.json().then(result => {

				switch (returned_status) {
					case 200: // Status: OK
						enableButtonByType(result["id"], result["request_type"]);
						if (result["success_msg"]) nbAlert(result["success_msg"], 5000);

						if (result["request_type"] === "patch") {
							let updatedFields = document.querySelectorAll("#device-row-" + result["id"] +
								" td:not(.row-submit)");
							updatedFields.forEach((tableField) => {
								let field = tableField.dataset.field
								let updatedValue = result["device"][field];
								if (updatedValue == null) updatedValue = "";
								tableField.dataset.originalValue = updatedValue
							});
						} else if (result["request_type"] === "delete") {
							let rowToHide = document.getElementById("device-row-" + result["id"])
							rowToHide.style.display = "none";
						}
						break;

					case 201: // Status: Created
						location.reload();
						break;

					case 400: // Status: Bad Request
						if (result["error"]) {
							let alertTimeout = result["alert_timeout"] ? result["alert_timeout"] : 0;
							let alertMsg = "Errors:\n"
							Object.keys(result["error"]).forEach((key) => {
								alertMsg += "  " + key + ": " + result["error"][key][0] + "\n";
							})
							nbAlert(alertMsg, alertTimeout);
						}
						if (result["id"] && result["request_type"]) {
							enableButtonByType(result["id"], result["request_type"]);
						}
						break;

					case 401: // Status: Unauthorized
						if (result["id"] && result["request_type"]) {
							enableButtonByType(result["id"], result["request_type"]);
						}
						nbAlert(result["error"], 5000);
						break;

				}

			})
		}).catch(error => console.log('error', error));
};