// Using https://developers.google.com/maps/documentation/javascript/custom-markers for help

deviceIndexMap = undefined;
deviceIndexMarkers = [];
let tippyInstances = undefined;
const statusTooltipKeys = [
	'active', 'ready', 'standby', 'disabled', 'unknown',
	'offline', 'error', 'initializing', 'stale',
]
const statusTooltips = {
	active: 'Actively charging.',
	ready: 'System is ready to charge.',
	standby: 'System is not ready to charge.',
	disabled: 'System has been disabled by a WAVE tech.',
	unknown: 'System is in an unkownm state.',
	error: 'The system has encountered an error.',
	initializing: 'System is initializing.',
	offline: 'Status has not been reported for more than 12 hours.',
	stale: 'Status has not been updated for a while.',
}
let chargerStatusTracker = []
let chargeCompleteCardIDs = []

document.addEventListener('DOMContentLoaded', function () {
	if (document.querySelector('.page-devices-index') == null &&
		document.querySelector('.page-devices-tv_mode') == null
		) {
		return;
	}

	// Enable Smooth Scroll using moveTo.js and after scrolling to map, callback sets the position and zoom on map
	const moveTo = new MoveTo();
	const devicePosTriggers = document.getElementsByClassName('devices-position-trigger');
	for (let trigger of devicePosTriggers) {
		moveTo.registerTrigger(trigger, () => {
			deviceIndexMap.setZoom(20);
			deviceIndexMap.panTo(new google.maps.LatLng(trigger.dataset.lat, trigger.dataset.lng));
		});
	}

	// Make tables sortable
	new Tablesort(document.getElementById('devices-index-chargers-table'));
	new Tablesort(document.getElementById('devices-index-vehicles-table'));

	// Set up a refresh interval for the current devices
	let defaultIntMin = 1.5;
	const today = new Date();
	const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
	const minimumInt = 	  67000;  // 67 seconds
	const maximumInt = 86400000;	// 24 hours
	const intInMinInput = document.getElementById("interval-in-minutes");

	if (!isNaN(intInMinInput.value))
		defaultIntMin = intInMinInput.value;
	let interval = (urlQueryParams.get('int-min') && !isNaN(urlQueryParams.get('int-min')))
			? (urlQueryParams.get('int-min') * 60000) : (60000 * defaultIntMin);
	if (interval < minimumInt) interval = minimumInt;
	else if (interval > maximumInt) interval = maximumInt;

	if (typeof tvModeEnabled !== 'undefined' && tvModeEnabled) {
		interval = 10000;
	}

	console.log('Refresh Interval set to: ' + interval / 1000 + ' seconds starting at ' + time)

	setInterval(() => {
	    getDevicesCurrentStatus();
	}, interval)

	document.addEventListener ("keydown", function (zEvent) {
		if (zEvent.altKey  &&  zEvent.key === "r") {  // case sensitive
			if ( !page_global_object.forceRefreshButtonsHeld ) getDevicesCurrentStatus();
			intInMinInput.classList.add("force-refresh-held");
			page_global_object.forceRefreshButtonsHeld = true;
		}
	} );

	document.addEventListener ("keyup", function (zEvent) {
		if (zEvent.altKey  &&  zEvent.key === "r") {  // case sensitive
			page_global_object.forceRefreshButtonsHeld = false;
		}
	} );

	setUpToolTips();

}, false);

devicesIndexMapCallback = function () {
	const devicesIndexMapElement = document.getElementById('devices-index-map');

	if (!devicesIndexMapElement) {
		console.log('Map Element Not Found.')
		return;
	}

	// https://www.iconfinder.com/iconsets/ios-7-icons

	const iconBase = '/assets/icons/';
	const icons = {
		primary: {icon: iconBase + 'charger-icon.png', zIndex: 0.0},
		secondary: {icon: iconBase + 'bus-icon.png', zIndex: 1.0},
		avta: {icon: iconBase + 'AVTA-bus-icon.png', zIndex: 2.0}
	};

	let devices = [];

	let deviceDataSets = [];
	document.querySelectorAll('.device-with-position').forEach(x => deviceDataSets.push(x.dataset));

	document.querySelectorAll('.device-with-position').forEach(device => {
		let deviceData = device.dataset;
		let type = deviceData.deviceType;
		let icon = icons[type];
		let transitSName = deviceData.deviceTransitShortname;

		if (parseInt(deviceData.deviceLat) === 0 && parseInt(deviceData.deviceLong) === 0) return;

		if (type === 'secondary' && icons[transitSName.toLowerCase()] !== undefined)
			icon = icons[transitSName.toLowerCase()];

		// Info Windows help: https://developers.google.com/maps/documentation/javascript/infowindows?authuser=3#open
		let currentSpeedText = '';
		if (parseFloat(deviceData.deviceSpeed) > 0) { // 1 mph = 1.609344 km/hr
			currentSpeedText = (Math.round(parseFloat(deviceData.deviceSpeed)).toString()) + " "
				+ user_unit_measurement['unit_abbr_per_hr'];
		}
		let iwState = deviceData.deviceState !== '' ? 'Status: ' + deviceData.deviceState : '';
		let iwSoC = (deviceData.deviceStateSoc !== '' && type !== 'primary')
				? '<br>SoC: ' + deviceData.deviceStateSoc + '%' : '';
		let iwKwOut = (deviceData.deviceStateKwOut !== '' && type == 'primary')
			? '<br>kW Out: ' + deviceData.deviceStateKwOut : '';
			let iwStateTime = deviceData.deviceState !== '' ? '<br>&nbsp; &nbsp; '
				+ deviceData.deviceStateTimestamp : '';
		let iwSpeed = (parseFloat(deviceData.deviceSpeed) > 0 && type !== 'primary')
				? '<br>Speed: ' + currentSpeedText : '';
		let iwPosTime = '<br>&nbsp; &nbsp; ' + deviceData.devicePosTimestamp;

		let iwText = '<h4 class="text-center">' + (type === 'primary' ? 'Charger' : 'Vehicle') + '</h4>';
		iwText += '<a href="' + deviceData.deviceLink + '" class="text-center"><h5>' + deviceData.deviceName + '</h5></a>'
		iwText += '<p class="info-line">' + iwState + iwSoC + iwKwOut + iwStateTime + '</p>';
		iwText += '<p class="info-line">Position: ' + deviceData.deviceLat + ', ' + deviceData.deviceLong + iwSpeed + iwPosTime + ' </p>';

		devices.push({
			position: new google.maps.LatLng(deviceData.deviceLat, deviceData.deviceLong),
			type: type,
			icon: icon,
			infoWindowText: iwText,
			deviceName: deviceData.deviceName
		});
	});

	if (devices.length < 1) {
		devicesIndexMapElement.style.display = "none";
		return;
	}

	let mapTypeId = 'hybrid'
	if (typeof differentMapTypeId !== 'undefined') { mapTypeId = differentMapTypeId }

	let deviceIndexMapOptions = {
		center: new google.maps.LatLng(39.216, -98.305),
		zoom: 4,
		mapTypeId: mapTypeId
	}

	if (typeof differentMapsStyle !== 'undefined') { deviceIndexMapOptions.styles = differentMapsStyle }
	if (typeof disableExtraControls !== 'undefined' && disableExtraControls) {
		deviceIndexMapOptions = {...deviceIndexMapOptions,
			...{
				streetViewControl: false,
				mapTypeControl: false,
				fullscreenControl: false
			}
		}
	}

	deviceIndexMap = new google.maps.Map(
			devicesIndexMapElement,
			deviceIndexMapOptions
		);

	// Bounds created with help from: https://stackoverflow.com/a/15720047/8345560
	let bounds = new google.maps.LatLngBounds();

	// Create Markers.
	const markers = devices.map((location, i) => {
		//creates the markers.
		let marker = new google.maps.Marker({
			position: devices[i].position,
			icon: devices[i].icon.icon,
			map: deviceIndexMap,
			zIndex: devices[i].icon.zIndex
		});

		//extend the bounds of the map, so that it shows all the markers
		bounds.extend(marker.position);

		// set up info window pop up per marker
		let infoWindow = new google.maps.InfoWindow({
			content: devices[i].infoWindowText
		});
		marker.addListener('click', function () {
			infoWindow.open(deviceIndexMap, marker);
			// map.setZoom(16);
			// map.setCenter(marker.getPosition());
		});

		deviceIndexMarkers.push({marker: marker, infoWindow: infoWindow, deviceName: devices[i].deviceName})

		return marker;
	});


	if (typeof mapDisableClustering !== 'undefined' && mapDisableClustering)
	{	}
	else {
		// https://developers.google.com/maps/documentation/javascript/marker-clustering
		// Add a marker clusterer to manage the markers.
		const markerClusterRenderer = {
			render({ count, position }, stats) {
				// change color if this cluster has more markers than the mean cluster
				const color = "#0000ff";
				// create svg url with fill color
				const svg = window.btoa(`
  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
		<rect rx="22%" height="140" width="140" y="50" x="50" opacity=".6"/>
		<rect rx="22%" height="180" width="180" y="30" x="30" opacity=".3"/>
		<rect rx="22%" height="220" width="220" y="10" x="10" opacity=".2"/>
  </svg>`);
				// create marker using svg icon
				return new google.maps.Marker({
					position,
					icon: {
						url: `data:image/svg+xml;base64,${svg}`,
						scaledSize: new google.maps.Size(45, 45),
					},
					label: {
						text: String(count),
						color: "rgba(255,255,255,0.9)",
						fontSize: "12px",
					},
					title: `Cluster of ${count} devices`,
					// adjust zIndex to be above other markers
					zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
				});
			}
		}

		const markerCluster = new markerClusterer.MarkerClusterer({
			map: deviceIndexMap,
			markers,
			renderer: markerClusterRenderer,
			algorithm: new markerClusterer.SuperClusterAlgorithm({minPoints: 2, minZoom: 0, maxZoom: 10})
		});
	}

	deviceIndexMap.fitBounds(bounds);

} // devicesMap

getDevicesCurrentStatus = function() {
	let myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", "Basic Og==");

	let chargerCounter = 0;
	let vehicleCounter = 0;
	let chargersOnline = 0;
	let vehiclesOnline = 0;
	let chargingCounter = 0;
	let onlineStates = ['Ready', 'Active', 'Standby', 'Initializing']
	let currentlyChargingContainer = document.getElementById('currently-charging');
	let doneChargingContainer = document.getElementById('done-charging');

	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	const fetchStart = new Date();
	const fetchStartTime = fetchStart.getHours() + ":" + fetchStart.getMinutes()
			+ ":" + fetchStart.getSeconds();

	fetch("/api/v1/current_statuses/", requestOptions)
			.then(response => response.text())
			.then(result => {
				let statuses = JSON.parse(result)['statuses']
				for (let i = 0; i < statuses.length; i++) {

					let state_update_time_diff = (new Date() - new Date(statuses[i]['state_updated_at'])) / (1000 * 60 * 60)
					if (statuses[i]['unit_type'] === 'primary') { //Chargers
						chargerCounter++;

						if (onlineStates.includes(statuses[i]['state']) && state_update_time_diff <= 1) {
							chargersOnline++;
						}

						if (statuses[i]['state'] === 'Active' && statuses[i]['kW_out'] > 0 && state_update_time_diff <= 1) {
							chargingCounter++;
						}

						try {
							let dev_db_id = statuses[i]['device_db_id'];

							if (typeof tvModeEnabled !== 'undefined' && tvModeEnabled) {
								if (chargerStatusTracker[dev_db_id] === undefined) {
									chargerStatusTracker[dev_db_id] = {
										charging: false,
										cardID: null,
										startTime: null,
										startSoC: null,
										mostRecentSoc: null,
										pairedDeviceName: null
									};
								}


								if (statuses[i]['state'] === 'Active' && statuses[i]['kW_out'] > 0 && state_update_time_diff <= (1/12)) {
									if (!chargerStatusTracker[dev_db_id]['charging']) {
										let startTime = new Date(statuses[i]['state_updated_at']);
										let cardID = `charge-card-${statuses[i]['name']}-${startTime.getTime() / 1000}`;
										let startSoC = statuses[i]['paired_soc'];
										let pairedDeviceName = statuses[i]['paired_name'];
										let kWOutBarPercent = statuses[i]['kW_out'] / statuses[i]['kW_limit']
											if (kWOutBarPercent > 1) kWOutBarPercent = 1
											kWOutBarPercent *= 100

										let chargingCard = document.createElement('div');
										chargingCard.classList.add('tv-mode-charging-card');
										chargingCard.id = cardID;

										let chargingCardCharger = document.createElement('div');
										chargingCardCharger.classList.add('tv-mode-charging-card-device', 'tv-mode-charging-card-charger');
										let chargingCardChargerBarContainer = document.createElement('div');
										chargingCardChargerBarContainer.classList.add('tv-mode-charging-card-device-bar-container');
										let chargingCardChargerBar = document.createElement('div');
										chargingCardChargerBar.classList.add('tv-mode-charging-card-device-bar');
										let chargingCardChargerName = document.createElement('div');
										chargingCardChargerName.classList.add('tv-mode-charging-card-device-name');
										let chargingCardChargerBarText = document.createElement('div');
										chargingCardChargerBarText.classList.add('tv-mode-charging-card-device-bar-text');

										chargingCardChargerName.innerText = statuses[i]['name'];
										chargingCardChargerBarText.innerText = `${Math.round(statuses[i]['kW_out'])} kW`;
										chargingCardChargerBar.style.width = `${Math.round(kWOutBarPercent * 10)/10}%`

										chargingCard.appendChild(chargingCardCharger)
										chargingCardCharger.appendChild(chargingCardChargerBarContainer)
										chargingCardChargerBarContainer.appendChild(chargingCardChargerBar)
										chargingCardChargerBarContainer.appendChild(chargingCardChargerName)
										chargingCardCharger.appendChild(chargingCardChargerBarText)

										let chargingCardNotes = document.createElement('div');
										chargingCardNotes.classList.add('tv-mode-charging-card-notes');
										let chargingCardNotesTimer = document.createElement('div')
										let minutesCharging = Math.round((new Date() - startTime) / 1000 / 60)
										chargingCardNotesTimer.classList.add('tv-mode-charging-card-notes-timer');
										chargingCardNotesTimer.innerText = `${minutesCharging} min${minutesCharging !== 1 ? 's' : ''}`
										let chargingCardNotesStartSoc = document.createElement('div')
										chargingCardNotesStartSoc.classList.add('tv-mode-charging-card-notes-start-soc');
										chargingCardNotesStartSoc.innerText = `Starting SoC: ${Math.round(startSoC)}%`

										chargingCardNotes.appendChild(chargingCardNotesTimer)

										if (statuses[i]['paired_id'] && parseInt(statuses[i]['paired_id']) !== 0 &&
											startSoC && startSoC > 0)
										{
											let chargingCardVehicle = document.createElement('div');
											chargingCardVehicle.classList.add('tv-mode-charging-card-device', 'tv-mode-charging-card-vehicle');
											let chargingCardVehicleBarContainer = document.createElement('div');
											chargingCardVehicleBarContainer.classList.add('tv-mode-charging-card-device-bar-container');
											let chargingCardVehicleBar = document.createElement('div');
											chargingCardVehicleBar.classList.add('tv-mode-charging-card-device-bar');
											let chargingCardVehicleName = document.createElement('div');
											chargingCardVehicleName.classList.add('tv-mode-charging-card-device-name');
											let chargingCardVehicleBarText = document.createElement('div');
											chargingCardVehicleBarText.classList.add('tv-mode-charging-card-device-bar-text');

											chargingCardVehicleName.innerText = pairedDeviceName ;
											chargingCardVehicleBarText.innerText = `${Math.round(startSoC)}%`;
											chargingCardVehicleBar.style.width = `${Math.round(startSoC * 10)/10}%`

											chargingCard.appendChild(chargingCardVehicle)
											chargingCardVehicle.appendChild(chargingCardVehicleBarContainer)
											chargingCardVehicleBarContainer.appendChild(chargingCardVehicleBar)
											chargingCardVehicleBarContainer.appendChild(chargingCardVehicleName)
											chargingCardVehicle.appendChild(chargingCardVehicleBarText)

											chargingCardNotes.appendChild(chargingCardNotesStartSoc)
										}

										chargingCard.appendChild(chargingCardNotes)

										chargerStatusTracker[dev_db_id]['charging'] = true;
										chargerStatusTracker[dev_db_id]['cardID'] = cardID;
										chargerStatusTracker[dev_db_id]['startTime'] = startTime;
										chargerStatusTracker[dev_db_id]['startSoC'] = startSoC;
										chargerStatusTracker[dev_db_id]['mostRecentSoc'] = startSoC;
										chargerStatusTracker[dev_db_id]['pairedDeviceName'] = pairedDeviceName;




										// chargingCard.appendChild(chargingCardVehicle)

										currentlyChargingContainer.prepend(chargingCard)


									} else if (chargerStatusTracker[dev_db_id]['charging']) {

										let chargingCard = document.getElementById(chargerStatusTracker[dev_db_id]['cardID'])

										if (chargerStatusTracker[dev_db_id]['pairedDeviceName'] !== statuses[i]['paired_name']) {
											doneChargingContainer.prepend(chargingCard)
											chargeCompleteCardIDs.push(chargerStatusTracker[dev_db_id]['cardID'])

											chargerStatusTracker[dev_db_id] = undefined
											i--;
											continue;
										}

										let kWOutBarPercent = statuses[i]['kW_out'] / statuses[i]['kW_limit']
										if (kWOutBarPercent > 1) kWOutBarPercent = 1
										kWOutBarPercent *= 100
										let currSoc = statuses[i]['paired_soc'];

										let chargingCardChargerBarText = chargingCard.querySelector(
											'.tv-mode-charging-card-charger .tv-mode-charging-card-device-bar-text')
										let chargingCardChargerBar = chargingCard.querySelector(
											'.tv-mode-charging-card-charger .tv-mode-charging-card-device-bar')

										chargingCardChargerBarText.innerText = `${Math.round(statuses[i]['kW_out'])} kW`;
										chargingCardChargerBar.style.width = `${Math.round(kWOutBarPercent * 10)/10}%`

										let startTime = chargerStatusTracker[dev_db_id]['startTime']
										let minutesCharging = Math.round((new Date() - startTime) / 1000 / 60)
										let chargingCardNotesTimer = chargingCard.querySelector('.tv-mode-charging-card-notes-timer')
										chargingCardNotesTimer.innerText = `${minutesCharging} min${minutesCharging !== 1 ? 's' : ''}`

										if (statuses[i]['paired_id'] && parseInt(statuses[i]['paired_id']) !== 0 &&
											currSoc && currSoc > 0)
										{
											let chargingCardVehicleBarText = chargingCard.querySelector(
												'.tv-mode-charging-card-vehicle .tv-mode-charging-card-device-bar-text')
											let chargingCardVehicleBar = chargingCard.querySelector(
												'.tv-mode-charging-card-vehicle .tv-mode-charging-card-device-bar')

											chargingCardVehicleBarText.innerText = `${Math.round(currSoc)}%`;
											chargingCardVehicleBar.style.width = `${Math.round(currSoc * 10)/10}%`

											chargerStatusTracker[dev_db_id]['mostRecentSoc'] = currSoc;
										}

									}

								} else { // when not (statuses[i]['state'] === 'Active')
									if (chargerStatusTracker[dev_db_id]['charging']) {
										let chargingCard = document.getElementById(chargerStatusTracker[dev_db_id]['cardID'])
										doneChargingContainer.prepend(chargingCard)
										chargeCompleteCardIDs.push(chargerStatusTracker[dev_db_id]['cardID'])

										let startTime = chargerStatusTracker[dev_db_id]['startTime']
										let minutesCharging = Math.round((new Date() - startTime) / 1000 / 60)
										let chargingCardNotesTimer = chargingCard.querySelector('.tv-mode-charging-card-notes-timer')
										let currStatusTime = new Date(statuses[i]['state_updated_at']).toLocaleTimeString([], { hour: 'numeric', minute: "2-digit" })
										chargingCardNotesTimer.innerText = `${minutesCharging} min${minutesCharging !== 1 ? 's' : ''} (${currStatusTime})`

										chargerStatusTracker[dev_db_id] = undefined
									}
								}

							}
						} catch (e) {
							console.log(e)
						}

					} else if (statuses[i]['unit_type'] === 'secondary') { //vehicles
						vehicleCounter++;

						if (onlineStates.includes(statuses[i]['state'])) {
							try {
								let state_update_time_diff = (new Date() - new Date(statuses[i]['state_updated_at'])) / (1000 * 60 * 60)
								if ( state_update_time_diff <= 1 ) {
									vehiclesOnline++;
								}
							} catch (e) {}
						}
					}

						setTimeout(() => {
						let deviceRow = document.querySelector('#row-' + statuses[i]['device_db_id']);
						if (!deviceRow) return;
						let deviceData = deviceRow.dataset;
						let statusText = document.querySelector('#status-' + statuses[i]['device_db_id'] + ' .status-text');
						let pairedDev = document.querySelector('#status-' + statuses[i]['device_db_id'] + ' .paired-device');
						let statusTime = document.querySelector('#status-' + statuses[i]['device_db_id'] + ' .check-time');
						let kwOutText = document.querySelector('#kw-out-' + statuses[i]['device_db_id']
								+ ' .bar-status-text');
						let kwOutBar = document.querySelector('#kw-out-' + statuses[i]['device_db_id'] + ' .bar-status-bar');
						let socText = document.querySelector('#soc-' + statuses[i]['device_db_id'] + ' .bar-status-text');
						let socBar = document.querySelector('#soc-' + statuses[i]['device_db_id'] + ' .bar-status-bar');
						let positionText = document.querySelector('#location-' + statuses[i]['device_db_id']
								+  ' .pos-text');
						let positionTime = document.querySelector('#location-' + statuses[i]['device_db_id']
								+ ' .check-time');
						let currentSpeed = document.querySelector('#location-' + statuses[i]['device_db_id']
							+ ' .device-speed');
						let currentOdometer = document.querySelector('#location-' + statuses[i]['device_db_id']
							+ ' .device-odo');



            if (statusText && statusTime) {
							statusText.innerText = capitalize(statuses[i]['state']);
							statusTime.innerText = statuses[i]['state_updated_at_tz'];
							statusTime.dataset.checkTime = statuses[i]['state_updated_at'];

							deviceData.deviceState = capitalize(statuses[i]['state']);
							deviceData.deviceStateTimestamp = statuses[i]['state_updated_at_tz'];

							if (statuses[i]['paired_id'] && parseInt(statuses[i]['paired_id']) !== 0)
							{
								// console.log(statuses[i]['paired_id']);
								pairedDev.innerHTML = `Connected to 
									<a href="/devices/${statuses[i]['paired_db_id']}">${statuses[i]['paired_name']}</a>`;
							} else {
								pairedDev.innerText = ''
							}
            }
            if (kwOutText) {
            	let kwOutWidth = statuses[i]['kW_out'] / statuses[i]['kW_limit'] * 100;
            	if (kwOutWidth > 100) kwOutWidth = 100;

            	kwOutText.innerText = statuses[i]['kW_out'];
							kwOutBar.style.width = kwOutWidth + '%';
						}
						if (socText) {
							socText.innerText = Math.round(statuses[i]['SoC']) + '%';
							socBar.style.width = statuses[i]['SoC'] + '%';

							deviceData.deviceStateSoc = Math.round(statuses[i]['SoC']).toString();
						}
						if (positionText) {
							let currentSpeedText = ''

							positionText.innerText = statuses[i]['latitude'] + ", " + statuses[i]['longitude'] ;
							positionText.dataset.lat = statuses[i]['latitude'];
							positionText.dataset.lng = statuses[i]['longitude'];
							positionText.dataset.test = 'Working';
							positionTime.innerText = statuses[i]['pos_updated_at_tz'];
							positionTime.dataset.checkTime = statuses[i]['pos_updated_at'];
							if (statuses[i]['speed'] > 0) { // 1 mph = 1.609344 km/hr
								currentSpeedText = Math.round(statuses[i]['speed']
									/ user_unit_measurement['unit_multiplier'] )  + " "
									+ user_unit_measurement['unit_abbr_per_hr'];
								currentSpeed.innerText = currentSpeedText
							}
							else currentSpeed.innerText = "";
							if (statuses[i]['odometer'] > 0) // 1 m = 1.609344 km
								currentOdometer.innerText = Math.round(statuses[i]['odometer']
									/ user_unit_measurement['unit_multiplier'] ) + " " + user_unit_measurement['unit_abbr'];
							else currentOdometer.innerText = "";

							deviceData.deviceLat = statuses[i]['latitude'];
							deviceData.deviceLong = statuses[i]['longitude'];
							deviceData.devicePosTimestamp = statuses[i]['pos_updated_at_tz'];

							let matchingMarkerIndex = deviceIndexMarkers.findIndex((marker) => {
								return statuses[i]['name'] === marker.deviceName
							})
							if (matchingMarkerIndex !== -1) {
								let iwState = statuses[i]['state'] !== ''
										? 'Status: ' + capitalize(statuses[i]['state']) : '';
								let iwSoC = (statuses[i]['SoC'] > 0 && statuses[i]['unit_type'] !== 'primary')
										? '<br>SoC: ' + Math.round(statuses[i]['SoC']) + '%' : '';
								let iwKwOut = (statuses[i]['kW_out'] > 0 && statuses[i]['unit_type'] === 'primary')
									? '<br>kW Out: ' + statuses[i]['kW_out'] : '';
								let iwStateTime = statuses[i]['state'] !== '' ? '<br>&nbsp; &nbsp; '
										+ statuses[i]['state_updated_at_tz'] : '';
								let iwSpeed = (statuses[i]['speed'] > 0 && statuses[i]['unit_type'] !== 'primary')
										? '<br>Speed: ' + currentSpeedText : '';
								let iwPosTime = '<br>&nbsp; &nbsp; ' + statuses[i]['pos_updated_at_tz'];

								let iwText = '<h4 class="text-center">'
										+ (statuses[i]['unit_type'] === 'primary' ? 'Charger' : 'Vehicle') + '</h4>';
								iwText += '<a href="' + deviceData.deviceLink + '" class="text-center"><h5>'
										+ deviceData.deviceName + '</h5></a>'
								iwText += '<p class="info-line">' + iwState + iwSoC + iwKwOut + iwStateTime + '</p>';
								iwText += '<p class="info-line">Position: ' + statuses[i]['latitude'] + ', '
										+ statuses[i]['longitude'] + iwSpeed + iwPosTime + ' </p>';

								deviceIndexMarkers[matchingMarkerIndex].marker.setPosition({
									lat: parseFloat(statuses[i]['latitude']),
									lng: parseFloat(statuses[i]['longitude'])
								});
								deviceIndexMarkers[matchingMarkerIndex].infoWindow.setContent(iwText)
							}

						}


					}, 16 * i);
				}

				const fetchEnd = new Date();

				console.log('New Update: ' + fetchStartTime + ' - Took '
					+ (fetchEnd - fetchStart)/1000 + ' seconds');

				setTimeout(() => {
					updateToolTips();
				}, 1500)

			}).catch(error => {console.log('error', error)
			}).finally(() => {
				try {
					if (typeof tvModeEnabled !== 'undefined' && tvModeEnabled) {
						let chargersOnlineText = document.getElementById('chargers-online-counter')
						let vehiclesOnlineText = document.getElementById('vehicles-online-counter')
						let chargingCounterText = document.getElementById('charging-counter')
						let chargingSectionHeader = document.getElementById('charging-section-header')

						chargersOnlineText.innerText = `${chargersOnline} / ${chargerCounter}`
						vehiclesOnlineText.innerText = `${vehiclesOnline} / ${vehicleCounter}`
						chargingCounterText.innerText = `${chargingCounter}`

						if (chargingCounter >= 1) {
							chargingSectionHeader.hidden = false
						} else {
							chargingSectionHeader.hidden = true
						}
					}
				} catch (e) {
					console.error('Something went wrong when updating TV Mode Headers')
				}



				while (chargeCompleteCardIDs.length > 100) {
					let cardID = chargeCompleteCardIDs[0];
					let chargingCard = document.getElementById(cardID)
					chargingCard.remove()
					chargeCompleteCardIDs.shift()
				}
			});


}



document.addEventListener('DOMContentLoaded', function () {
	// Reload the TV Mode page everyday at 4 AM
	try {
		if (typeof tvModeEnabled !== 'undefined' && tvModeEnabled) {
			let futureTime = new Date();
			futureTime.setDate(futureTime.getDate() + 1);
			futureTime.setHours(4, 0, 0, 0);
			let getDiffInTime = futureTime - new Date();
			console.log('The page will auto reload at: ' + futureTime)

			setTimeout(() => {
				location.reload();
			}, getDiffInTime)
		}
	} catch (e) { }
}, false);

function setUpToolTips() {
	tippyInstances = tippy('.status-text');

	tippyInstances.forEach((ti) => {
		ti.setProps({
			placement: 'top-start'
		})
		let content = ''

		statusTooltipKeys.forEach((stk) => {
			if ( ti.reference.innerText.toLowerCase().includes(stk) )
				content  += capitalize(stk) + ": " + statusTooltips[stk] + ' '

		})

		ti.setContent(content);
	})
}

function updateToolTips() {
	tippyInstances.forEach((ti) => {
		let content = ''
		let foundNoMatch = true

		statusTooltipKeys.forEach((stk) => {
			if ( ti.reference.innerText.toLowerCase().includes(stk) ) {
				content  += capitalize(stk) + ": " + statusTooltips[stk] + ' '
				foundNoMatch = false
			}
		})

		if (foundNoMatch) console.log(ti.reference.innerText + ' Found no Match')

		ti.setContent(content);
	})

}