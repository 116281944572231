document.addEventListener('DOMContentLoaded', function () {

	// make sure only runs on individual device page
	if (document.querySelector('.page-devices-show') == null) {
		return;
	}

	setUpDeviceStatusOverTime()

	getDeviceEvents(deviceInfo.ID);

	setUpChargeDataSection();
}, false);


// Initiates the Status Graph for the device
function setUpDeviceStatusOverTime() {
	let deviceSocOverTime = document.getElementById('device-status-over-time');
	let deviceOverTimeWrapper = document.getElementById('device-status-over-time-graph-wrapper');

	if (!deviceSocOverTime) return;

	if (statuses.length === 0) {
		deviceSocOverTime.parentElement.style.display = "none";
		return;
	}

	statuses.reverse(); // Reverses the order of statuses, so the oldest is first

	// Define variables
	let SoCLastCreatedAt = new Date(statuses[0].created_at).addHoursCurrUserTz(0);
	let SoCLapseHrs = 12;
	let kWhOverTime = 0;
	let kWhWirelessChargeTotal = 0;
	let maxVolts = 0;
	let kWLimitYAxis = 'y6';
	let kWInName = 'kW in'
	let kWOutName = 'kW out'


	if (deviceInfo.unit_type === "primary" ) {
		kWLimitYAxis = 'y2';
		kWInName = 'kW grid';
		kWOutName = 'kW charge';
	} else 	if (deviceInfo.unit_type === 'secondary') {
		kWInName = 'kW wireless charge';
		kWOutName = 'kW battery';
	}

	// Define all Traces (lines and bars
	let socTrace = {
		x: [], y: [], type: 'scatter', name: 'SoC', yaxis: 'y1', xaxis: 'x', line: {color: '#1f77b4'},
	};
	let kWInTrace = {
		x: [], y: [], type: 'bar', name: kWInName, yaxis: 'y6', xaxis: 'x', marker: {color: '#faa528'},
	};
	let kWOutTrace = {
		x: [], y: [], type: 'bar', name: kWOutName, yaxis: 'y2', xaxis: 'x', marker: {color: 'red'},
	};
	let kWLimitTrace = {
		x: [], y: [], type: 'scatter', name: 'kW limit', yaxis: kWLimitYAxis, xaxis: 'x',
		line: {color: 'darkred', width: 1}, opacity: 0.5
	};
	let kWhInTrace = {
		x: [], y: [], type: 'bar', name: 'kWh in', yaxis: 'y3', xaxis: 'x', marker: {color: 'green'},
	};
	let kWhOutTrace = {
		x: [], y: [], type: 'bar', name: 'kWh out', yaxis: 'y7', xaxis: 'x', marker: {color: 'blue'},
	};
	let voltsTrace = {
		x: [], y: [], type: 'scatter', name: 'Volts', yaxis: 'y4', xaxis: 'x', line: {color: '#f5c43d'},
	};
	let kWhTotalTrace = {
		x: [], y: [], type: 'scatter', name: 'kWh Used', yaxis: 'y5', xaxis: 'x', line: {color: '#bdbdbd'},
	};
	let kWhWirelessChargeTotalTrace = {
		x: [], y: [], type: 'scatter', name: 'Wireless kWh', yaxis: 'y5', xaxis: 'x', line: {color: 'green'},
	};
	let chargesTrace = {
		x: [], y: [], type: 'scatter', name: 'Charges', mode: 'markers', text: [], marker: {size: 8},
		yaxis: 'y1', xaxis: 'x',
	}

	const cellTempKeys = [
		"temp_min_cell", "temp_max_cell",
		"voltage_min_cell", "voltage_min_cell"
	]
	const cellTempTraces = [
		{x: [], y: [], type: 'scatter', name: "Min Temp", yaxis: 'y8', line: {color: '#a1a1ff'}},
		{x: [], y: [], type: 'scatter', name: "Max Temp", yaxis: 'y8', line: {color: '#1448e6'}},
		{x: [], y: [], type: 'scatter', name: "Min Voltage", yaxis: 'y9', line: {color: '#ffae66'}},
		{x: [], y: [], type: 'scatter', name: "Max Voltage", yaxis: 'y9', line: {color: '#cc4429'}},
	]
	let cellTempDataExists = false;

	// Go through each status and make points or bars for each data point

	let currChargeIndex = deviceChargesInfo.length - 1; // Start from the end and go backwards
	let currChargeCreatedAt;
	if (currChargeIndex >= 0)
		currChargeCreatedAt = new Date(deviceChargesInfo[currChargeIndex]['created_at']).addHoursCurrUserTz(0);
	statuses.forEach((status) => {

		let currentStatusCreatedAt = new Date(status.created_at).addHoursCurrUserTz(0);
		let statusChargeDiff
		if (currChargeIndex >= 0)
			statusChargeDiff = currChargeCreatedAt - currentStatusCreatedAt;

		if (status.SoC !== 0) {
			if (Math.abs(SoCLastCreatedAt - currentStatusCreatedAt) / 1000 / 60 / 60 > SoCLapseHrs) {
				socTrace.x.push(null);
				socTrace.y.push(null);
			}
			socTrace.x.push(currentStatusCreatedAt);
			socTrace.y.push(status.SoC);
			SoCLastCreatedAt = currentStatusCreatedAt;

		}

		if (currChargeIndex >= 0 && statusChargeDiff < 0) {
			chargesTrace.y[currChargeIndex] = status.SoC;
			currChargeIndex--;
			if (currChargeIndex >= 0)
				currChargeCreatedAt = new Date(deviceChargesInfo[currChargeIndex]['created_at']).addHoursCurrUserTz(0);
		}

		if (status.kW_in !== 0) {
			kWInTrace.x.push(currentStatusCreatedAt);
			kWInTrace.y.push(status.kW_in);
		}

		if (status.kW_out !== 0) {
			kWOutTrace.x.push(currentStatusCreatedAt);
			kWOutTrace.y.push(status.kW_out);
		}

		if (status.kW_limit !== 0) {
			kWLimitTrace.x.push(currentStatusCreatedAt);
			kWLimitTrace.y.push(status.kW_limit);
		}

		if (status.kWh_in !== 0) {
			kWhInTrace.x.push(currentStatusCreatedAt);
			kWhInTrace.y.push(status.kWh_in);
		}

		if (status.kWh_out !== 0) {
			kWhOutTrace.x.push(currentStatusCreatedAt);
			kWhOutTrace.y.push(status.kWh_out);
		}

		if (deviceInfo.unit_type === "primary" && status.kWh_in && status.kWh_in > 0) {
			kWhOverTime += status.kWh_in;
		} else {
			kWhOverTime += status.kWh_out && status.kWh_out > 0 ? status.kWh_out : 0;
		}
		kWhTotalTrace.x.push(currentStatusCreatedAt);
		kWhTotalTrace.y.push(kWhOverTime);

		if (deviceInfo.unit_type === 'secondary') {
			if (status.kWh_in && status.kWh_in > 0)
				kWhWirelessChargeTotal += status.kWh_in;
			if (kWhWirelessChargeTotal > 0) {
				kWhWirelessChargeTotalTrace.x.push(currentStatusCreatedAt);
				kWhWirelessChargeTotalTrace.y.push(kWhWirelessChargeTotal)
			}
		}

		if (status.volts !== 0) {
			voltsTrace.x.push(currentStatusCreatedAt);
			voltsTrace.y.push(status.volts);
			if (status.volts > maxVolts) maxVolts = status.volts
		}

		cellTempKeys.forEach((cellTempKey, i) => {
			const datum = status[cellTempKey]
			if (datum > 0) {
				cellTempTraces[i].x.push(currentStatusCreatedAt)
				cellTempTraces[i].y.push(datum)
				cellTempDataExists = true;
			}
		})

	});

	//Creates the bars that go behind the graph to indicate charges
	let chargeShapes = []
	deviceChargesInfo.forEach((chargeInfo, index) => {
		let createdAt = new Date(chargeInfo['created_at']).addHoursCurrUserTz();
		if (chargeInfo['charge_time'] >= 60) {
			chargeShapes.push({
				type: 'rect',
				xref: 'x',
				yref: 'paper',
				x0: new Date(chargeInfo['charge_start_at']).addHoursCurrUserTz(),
				y0: 0,
				x1: createdAt,
				y1: 1,
				fillcolor: '#c5d3d8',
				opacity: 0.3,
				layer: 'below',
				line: {
					width: 0
				}
			})

			chargesTrace.x[index] = createdAt;
			let paired_device_name =  chargeInfo['paired_device'] ?
				"Paired Device:      " +chargeInfo['paired_device']['name'] + "<br>" : "";
			chargesTrace.text[index] = paired_device_name + "Charge Duration:  " + chargeInfo['charge_duration']
		}
	})

	// The data that will be used in the graph
	let data = [socTrace, kWInTrace, kWOutTrace, kWLimitTrace, kWhInTrace, kWhOutTrace, voltsTrace,
		kWhTotalTrace, kWhWirelessChargeTotalTrace, chargesTrace];

	// Add the cellTemp Data if data exists
	if (cellTempDataExists)
		data = data.concat(cellTempTraces);

	plotXMin = new Date(statuses[0].created_at).addHoursCurrUserTz(-1);
	plotXMax = new Date(statuses[statuses.length - 1].created_at).addHoursCurrUserTz(1);



	// Define the layout of the plot
	const layout = {
		showlegend: true,
		barmode: 'group',
		shapes: chargeShapes,
		grid: {
			rows: 6, columns: 1, // roworder:'bottom to top',
			subplots: [['xy4'], ['xy2'], ['xy6'], ['xy7'], ['xy3'], ['xy5']],
		},
		legend: {
			font: {size: 12}, borderwidth: '1', orientation: 'h',
			x: 0.5, y: 1.025, xanchor: 'center', yanchor: 'bottom'
		},
		yaxis: {
			range: [105 * -0.1, 105], title: 'SoC (%)', titlefont: {color: '#1f77b4'},
			overlaying: 'y4', anchor: 'x', showgrid: false
		},
		yaxis2: {title: 'kW out', anchor: 'x',},
		yaxis6: {title: 'kW in', anchor: 'x'},
		yaxis7: {title: 'kWh out', anchor: 'x'},
		yaxis3: {title: 'kWh in', anchor: 'x',},
		yaxis4: {
			range: [maxVolts * 1.1 * -0.1, maxVolts * 1.1], title: 'Volts',
			titlefont: {color: '#f5c43d'}, showgrid: false, side: 'right', anchor: 'x'
		},
		yaxis5: {title: 'kWh over time', anchor: 'x'},
		xaxis: {
			title: 'Time (' + currentUserTz.abbreviation + ')',
			range: [plotXMin,plotXMax],
		},
	};

	// Add subplot and axes to plot if cell temp data exists
	if (cellTempDataExists) {
		layout.grid.rows++;
		layout.grid.subplots.splice(1,0,['xy8'])
		layout.yaxis8 = {title: 'Degrees (C°)', anchor: 'x', range: [5,65], dtick: 10}
		layout.yaxis9 = {title: 'Voltage', anchor: 'x', range: [1.8,4.2], side: 'right', overlaying: 'y8', dtick: 0.4}
		try {
			deviceOverTimeWrapper.style.paddingTop =
				(parseInt(deviceOverTimeWrapper.style.paddingTop) + 150) + 'px';
		} catch {}
	}

	// Plot configuration
	const config = {
		responsive: true,
		toImageButtonOptions: { //.downloadImage to start download using javascript
			format: 'png', filename: 'status_plot',
			height: 900, width: 1200, scale: 1
		},
	};

	Plotly.newPlot(deviceSocOverTime, data, layout, config);

	deviceSocOverTime.on('plotly_click', function(data){
		for (let i = 0; i < data.points.length; i++) {
			let curve = data.points[i];
			if (curve.data.name === "Charges") {
				let chargeId = deviceChargesInfo[curve.pointIndex].details_id;
				let tableRow = document.getElementById("charge-data-" + chargeId);
				let blueColor = "#17becf4f";
				tableRow.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
				tableRow.style.backgroundColor = blueColor;
				tableRow.style.outlineColor = blueColor;
				setTimeout(() => {
					tableRow.style.backgroundColor = "";
					tableRow.style.outlineColor = "";
				}, 3000)
				break;
			}
		}
		//$0.style.backgroundColor = "#17becf4f"
		//$0.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
	});

	setupResizePlotsOnRelayout()
}

DeviceShowMapCallback = function () {
	let mapElement = document.getElementById('device-show-map');

	if (document.querySelector('.page-devices-show') == null) {
		return;
	} else if (!mapElement) {
		console.log('Map Element Not Found.')
		return;
	}


	if (positions.length === 0 || (positions[0].latitude === 0 && positions[0].longitude === 0)) {
		mapElement.style.display = "none";
		return;
	}

	window.deviceDetailMap = new google.maps.Map(mapElement, {
		center: new google.maps.LatLng(39.216, -98.305), zoom: 4, maxZoom: 17,
		mapTypeId: 'hybrid'
	});
	const unitType = deviceInfo.unit_type;
	const iconBase = '/assets/icons/';
	const icons = {
		primary: {icon: iconBase + 'charger-icon.png', zIndex: 0.0},
		secondary: {icon: iconBase + 'bus-icon.png', zIndex: 1.0},
		avta: {icon: iconBase + 'AVTA-bus-icon.png', zIndex: 2.0}
	};

	let posCounted = 0, latTotal = 0, lngTotal = 0;
	// https://developers.google.com/maps/documentation/javascript/examples/polyline-simple
	let deviceCoordinates = [];
	positions.forEach(coordinate => {
		let lat = parseFloat(coordinate.latitude);
		let lng = parseFloat(coordinate.longitude);
		if (lat === 0 && lng === 0) return;
		deviceCoordinates.push({lat: lat, lng: lng})
		posCounted++;
		latTotal += lat;
		lngTotal += lng;
	});

	let strokeOpacity = unitType === 'secondary' ? 0.75 : 0;
	const positionPath = new google.maps.Polyline({
		path: deviceCoordinates,
		geodesic: true,
		strokeColor: '#000080',
		strokeOpacity: strokeOpacity,
		strokeWeight: 3
	});

	let markerPos = unitType === 'secondary'
		? new google.maps.LatLng(parseFloat(positions[0].latitude), parseFloat(positions[0].longitude))
		: new google.maps.LatLng(latTotal / posCounted, lngTotal / posCounted);
	new google.maps.Marker({
		position: markerPos,
		icon: icons[unitType].icon,
		map: deviceDetailMap,
		zIndex: icons[unitType].zIndex
	});

	positionPath.setMap(deviceDetailMap);


	if (unitType === 'primary') {
		// map.panTo(markerPos);
	} else if (unitType === 'secondary') {

	}

	// Bounds created with help from: https://stackoverflow.com/a/15720047/8345560
	let bounds = new google.maps.LatLngBounds();

	// bounds with path: https://stackoverflow.com/a/36489756/8345560
	deviceCoordinates.forEach(function (coordinate) {
		bounds.extend(new google.maps.LatLng(coordinate['lat'], coordinate['lng']));
	});
	deviceDetailMap.fitBounds(bounds);

	google.maps.event.addListenerOnce(deviceDetailMap, 'idle', function () {
		deviceDetailMap.setOptions({maxZoom: undefined})
	});

};

getDeviceEvents = function (id, limit, offset) {
	let eventLoadMoreValue = document.getElementById('load-more-events-input').value;
	if (!isNaN(parseInt(eventLoadMoreValue))) limit = parseInt(eventLoadMoreValue) + 10;

	let myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", "Basic Og==");

	let requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	let url = "/api/v1/events/" + id + "?"
	if (limit) url += "&limit=" + limit
	if (offset) url += "&offset=" + offset

	let eventLoadMore = document.getElementById('event-button-load-more');
	let eventsLoadingSpinner = document.querySelector('#event-section .loading-spinner');

	if (eventsLoadingSpinner) eventsLoadingSpinner.style.display = "block";

	fetch(url, requestOptions)
	.then(response => response.json())
	.then(result => {
		let eventsTableBody = document.querySelector('#event-section table tbody')

		let events = result.events

		let i;

		for (i = 0; i < events.length; i++) {
			let event = events[i];
			let trNode = document.createElement("tr");
			let timeNode = document.createElement("td");
			let eventNode = document.createElement("td");
			let eventIdNode = document.createElement("td");
			let eventDetailNode = document.createElement("td");
			let pairedDevNode = document.createElement("td");
			let eventDetailNodeText = "";

			let eventAlreadyLoaded = false;
			loadedEvents.forEach((loadedEvent) => {
				if (loadedEvent.id === event.id) eventAlreadyLoaded = true;
			})
			if (eventAlreadyLoaded) continue;

			loadedEvents.push(event);

			timeNode.innerText = event.created_at_tz;
			eventNode.innerText = event.event;
			eventIdNode.innerText = event.event_id;
			if (event.event_details) {
				let excludeKeys = ["created_at", "updated_at", "id", "alignment_score"]
				Object.keys(event.event_details).forEach((key) => {
					if (excludeKeys.includes(key)) return;
					eventDetailNodeText += key + ": " + event.event_details[key] + "<br>";
				});

				let eventDetailNodeInnerHTML =
					`<div class="event-details" tabindex="-1">` +
					`<a href="javascript:void(0)" class="event-details-show-more accordion" 
						for="accordion-${event.detail_type}-${event.event_id}">
						<span class="pos">More</span> <span class="neg">Hide</span> Details<br></a>` ;

				if (event.event_details["alignment_score"] && !isNaN(event.event_details["alignment_score"])) {
					eventDetailNodeInnerHTML += `<div>Alignment Score: ${event.event_details["alignment_score"]}</div>`
				}

				eventDetailNodeInnerHTML += `<div class="accordion-panel" 
						id="accordion-${event.detail_type}-${event.event_id}">${eventDetailNodeText}</div></div>`


				eventDetailNode.innerHTML = eventDetailNodeInnerHTML

			}
			if (event.paired_db_id) {
				pairedDevNode.innerHTML = `<a href="/devices/${event.paired_db_id}">${event.paired_name}</a>`
			}

			trNode.appendChild(timeNode);
			trNode.appendChild(eventNode);
			trNode.appendChild(eventIdNode);
			trNode.appendChild(pairedDevNode);
			trNode.appendChild(eventDetailNode);
			eventsTableBody.appendChild(trNode);

		}

		eventLoadMore.innerText = "Load";
		eventLoadMore.disabled = false;

		if (eventsLoadingSpinner) eventsLoadingSpinner.style.display = "none";


	}).catch(error => console.log('error', error))
				.then( () => setUpAccordions());

}

function setUpChargeDataSection() {
	let chargeDataTableHeight = document.getElementById('charge-data-table').offsetHeight
	let windowHeight = window.innerHeight

	if (chargeDataTableHeight <= windowHeight*0.6) {
		document.getElementById('charge-data-section').style.maxHeight = chargeDataTableHeight + 1 + 'px'
	}
}


