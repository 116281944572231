// document.addEventListener('DOMContentLoaded', function () {
//
// }, false);

let nbAlertTimeout;

/**
 * This will create a non-blocking alert. Basically looks like the chrome's alert() pop up, but does not
 * 		block website usage
 * @param {string} msg					The message within the non-blocking alert
 * @param {integer} [timeout] 	How many milliseconds before the alert disappears. If not set will keep
 * 		alert open until user closes it.
 */
nbAlert = function(msg, timeout = 0) {	//non-blocking alert system
	let alertBox = document.getElementById("non-blocking-alert");
	let alertText = document.querySelector("#non-blocking-alert .non-blocking-alert-text");
	window.clearTimeout(nbAlertTimeout);

	alertBox.style.display = "block";
	alertText.innerHTML = "<div style='color: #e8eaed;'>Alert:</div><br>" +
		"<div class='text-body'>" + msg + "</div>";

	if (timeout > 0) {
		nbAlertTimeout = setTimeout(() => {
			alertBox.style.display = "none";
		}, timeout)
	}
}



/**
 * Given an input field, updates the text withim limitSpanID to the
 * length of the characters in the input field
 * @param field					input field
 * @param limitSpanID		the ID of the element whose text will be updated
 */
updateFieldLength = function(field, limitSpanID) {
	let limitSpan = document.getElementById(limitSpanID)
	limitSpan.innerText = field.value.length;
}