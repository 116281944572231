let deviceElements;

document.addEventListener('DOMContentLoaded', function () {

	if (document.querySelector('.page-devices-overview') == null) {
		return;
	}

	deviceElements = {
		total: {
			chargeEvents: document.querySelector("#total .charge-events"),
			chargeSuccess: document.querySelector("#total .charge-success"),
			primaryPowerCharged: document.querySelector("#total .primary-power-charged"),
			secondaryPowerCharged: document.querySelector("#total .secondary-power-charged"),
			loadingBar: document.querySelector("#total .loading-bar"),
		},
		primary: {
			stateCounts: document.querySelector("#primary .state-counts"),
			quickViews: document.querySelector("#primary .quick-view"),
		},
		secondary: {
			stateCounts: document.querySelector("#secondary .state-counts"),
			quickViews: document.querySelector("#secondary .quick-view"),
		}
	}

	checkEventsForErrors();
	getDevices();

}, false);

/** Device information holder and manipulator */
class Device {
	static devices = [];
	static statusCount = { primary: {}, secondary: {} }
	static totalChargeEvents = {chargeEvents: 0, powerCharged: 0}

	/**
	 * @param {int} device				device info from database
	 */
	constructor(device) {
		this.info = device;
		this.id = device.ID;
		Device.devices.push(this);
	}

	static setUpStatusCounts(unitType) {
		let statusCounts = Device.statusCount[unitType];
		let statusCountsArr = []
		for(var i in statusCounts)
			statusCountsArr.push([i, statusCounts[i]])
		statusCountsArr.sort((a,b) => { if (a[0] >= b [0])  return 1; else return -1; 	})

		statusCountsArr.forEach( (statusCount) => {
			let stateNode = document.createElement("li");
			stateNode.innerHTML = `${capitalize(statusCount[0])}: <br>${statusCount[1]}`
			deviceElements[unitType].stateCounts.appendChild(stateNode);
		})
	}

	static async setUpTotalChargeEvents() {
		let currTime = new Date();
		let timeStart = currTime.getTime();
		console.log(`Fetching Charges Count and Power Charged - ${currTime.getHours()}:${currTime.getMinutes()}:${currTime.getSeconds()}` )

		let startDateTime = document.querySelector("input[type='text'][id*='start_date_time']").value
		let endDateTime = document.querySelector("input[type='text'][id*='end_date_time']").value
		let dateParams = `&end-time=${endDateTime}&start-time=${startDateTime}`;

		console.log(startDateTime, endDateTime)

		for (let i = 0; i < Device.devices.length; i++) {
			let device = Device.devices[i];
			let device_id = device.info.device_id
			let unitType = device.info.unit_type;

			let innerHTMLText =
				`<a href="/devices/${device.id}" target="_blank">${device.info.name}</a><br>${device.info.device_id}<br>`;

			let powerChargedResp = await fetch(`/api/v1/events/powerCharged/${device_id}?` + dateParams);
			let powerChargedResult = await powerChargedResp.json();
			device.sum = powerChargedResult.powerCharged;

			if (device.sum != null && device.sum) {
				let roundedSum = Math.round(device.sum*10)/10;
				let powerCharged = deviceElements.total[unitType + "PowerCharged"].innerText
				innerHTMLText += `${roundedSum} kWh <br> `;
				powerCharged = parseFloat(powerCharged) + roundedSum;
				powerCharged = Math.round(powerCharged*10)/10
				deviceElements.total[unitType + "PowerCharged"].innerText = powerCharged
			}

			if (unitType === 'primary') {
				let countResp = await fetch(
					'/api/v1/events/chargeCount/' + device_id + '?' + dateParams);
				let countResult = await countResp.json();
				let count = countResult.chargeCount;
				let successfulCount = countResult.chargesGESixty
				if (successfulCount == null) successfulCount = 0;

				if (count > 0) {
					deviceElements.total.chargeEvents.innerText =
						parseInt(deviceElements.total.chargeEvents.innerText) + count;
					deviceElements.total.chargeSuccess.innerText =
						parseInt(deviceElements.total.chargeSuccess.innerText) + successfulCount;

					if (count === 1)
						innerHTMLText += `${count} Attempt <br> `;
					else
						innerHTMLText += `${count} Attempts <br> `;

					if (successfulCount === 1)
						innerHTMLText += `${successfulCount} Charge <br> `;
					else
						innerHTMLText += `${successfulCount} Charges <br> `;
				}
			}

			let chargeEventNode = document.createElement("li");
			chargeEventNode.innerHTML = innerHTMLText;
			deviceElements[unitType].quickViews.appendChild(chargeEventNode);


			deviceElements.total.loadingBar.style.width = (((i+1)/Device.devices.length)*100) + "%"

		}

		currTime = new Date();
		let timeEnd = currTime.getTime();
		console.log("Took " + (timeEnd - timeStart)/1000 + " seconds" + ` - ${currTime.getHours()}:${currTime.getMinutes()}:${currTime.getSeconds()}`)
		deviceElements.total.loadingBar.parentNode.style.display = "none";
	}
}

/** Build out the Overview page based on objects */
function renderOverview() {
	Device.setUpStatusCounts("primary");
	Device.setUpStatusCounts("secondary");

	Device.setUpTotalChargeEvents();

}

/** Get current results from the data base for the devices */
function getDevices() {
	let myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", "Basic Og==");

	let requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	fetch("/api/v1/devices?trans=" + urlQueryParams.get('trans'), requestOptions)
	.then(response => response.json())
	.then(result => {
		result.devices.forEach((dev) => {
			new Device(dev);
		});
		getCurrentStatuses();
	})
	.catch(error => console.log('error', error));
}

/** Get current results from the data base for the devices */
function getCurrentStatuses() {
	let myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", "Basic Og==");

	let requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	fetch("/api/v1/current_statuses?trans=" + urlQueryParams.get('trans'), requestOptions)
	.then(response => response.json())
	.then(result => {
		result.statuses.forEach((status) => {
			let foundDevice = false;
			for (let i = 0; i < Device.devices.length; i++) {
				try {
					if (parseInt(status.device_id) === Device.devices[i].info.ID) {
						Device.devices[i].status = status;
						Device.devices[i].state = status.state;
						Device.devices[i].unit_type = status.unit_type;
						foundDevice = true;
					}
				} catch (e) {
					console.log(e.message)
				}
			}
			if (!foundDevice) console.log("Device not found for status: ", status)
			else {
				if (Device.statusCount[status.unit_type][status.state] === undefined)
					Device.statusCount[status.unit_type][status.state] = 1;
				else Device.statusCount[status.unit_type][status.state]++;
			}
		});
		renderOverview();
	})
	.catch(error => console.log('error', error));
}