// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("custom/site-wide");
require("custom/devices-index");
require("custom/devices-show");
require("custom/devices-overview");
require("custom/devices-edit_all");
require("custom/modal-pop-up");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/*GlobalVariables*/

// Gets the query string and makes it available globally as urlQueryParams
urlQueryParams = new URLSearchParams(window.location.search);
// Used for how stale (old) a device status/position update is
redCodeHrs = 12;
orangeCodeHrs = 4;
yellowCodeHrs = 0.5;


/**
 * Get the difference between two times in seconds.
 *
 * @param {Date} time1 - The first time
 * @param {Date} time2 The second time
 * @returns {number} time difference in seconds. Always positive.  */
getTimeDiffInSecs = function(time1, time2) {
	let timeDiffInSecs = (time1 - time2) / 1000;
	if (timeDiffInSecs < 0) timeDiffInSecs *= -1;
	return timeDiffInSecs;
}

/**
 * Gets a color code if the Time difference is larger than set amounts. 3600 seconds makes an hour.
 * @param {number} timeDiffInSecs the number to be tested
 * @returns {string|boolean} either a color code or false if no timeDiffInSecs does not exceed
 * 		any set times.
 */
getTimeDiffCode = function(timeDiffInSecs) {
	let code
	if (timeDiffInSecs/3600 > redCodeHrs) {
		code = "red"
	} else if (timeDiffInSecs/3600 > orangeCodeHrs) {
		code = "orange"
	} else if (timeDiffInSecs/3600 > yellowCodeHrs) {
		code = "yellow"
	} else {
		code = false;
	}
	return code;
}

/**
 * Capitalizes the first character of a string. If param given not a string, returns false.
 * @param {string} string	The string to capitalize
 * @returns {string|boolean}
 */
capitalize = function(string) {
	if (typeof string === "string")
		return string.charAt(0).toUpperCase() + string.slice(1);
	else
		return false;
}


// with help from: https://www.w3schools.com/howto/howto_js_accordion.asp
setUpAccordions = function() {
	const accordions = document.getElementsByClassName("accordion");
	for (let i = 0; i < accordions.length; i++) {
		let acc = accordions[i];
		if(!acc.hasAccordion) {
			accordions[i].addEventListener("click", function() {
				/* Toggle between adding and removing the "active" class,
				to highlight the button that controls the panel */
				this.classList.toggle("active");

				/* Toggle between hiding and showing the active panel */
				let forAttr = this.getAttribute('for');
				let panel = document.getElementById(forAttr);
				panel.classList.toggle("active")
			});
			// console.log('Accordion Set Up')
			acc.hasAccordion = true;
		}

	}
}

/**
 * Smoothly scrolls to Element and centers it to page
 * @param scrollToElement      Element to scroll to
 */
smoothScrollToAndCenter = function (scrollToElement) {
	let bodyRect = document.body.getBoundingClientRect(),
		scrollToElementRect = scrollToElement.getBoundingClientRect(),
		offset   = scrollToElementRect.top - bodyRect.top;

	window.scrollTo({
		left: 0,
		top: offset - (window.innerHeight/2),
		behavior: "smooth"
	});
}

/**
 * Expands the Date Object with addHours method, so that you can specify how many hours to add to the date
 * @param h		hours to offset
 * @returns {Date}
 */
Date.prototype.addHoursCurrUserTz = function (h = 0) {
	this.setTime(this.getTime() + (
		(h + parseFloat(currentUserTz.offset) + this.getTimezoneOffset() / 60) * 60 * 60 * 1000));
	return this;
};