/**
 *
 * @param {String} title			What will be displayed at the top of the Modal
 * @param textId							The HTML that will be cloned into the Modal body
 *
 * @return {String}						The ID of the newly created modal
 * @author Arash Rasteh
 */
openModalPopUp = function(title, textId) {
	const html = document.documentElement;
	const body = document.body;
	const text = document.getElementById(textId);
	const textClone = text.cloneNode(true);

	const docHeight = Math.max( body.scrollHeight, body.offsetHeight,
		html.clientHeight, html.scrollHeight, html.offsetHeight );

	textClone.hidden = false;

	const popUpBG = document.createElement("div");
	popUpBG.classList.add('pop-up-modal-background');
	popUpBG.id = textId + '-popUp-' +
		Math.floor(((Math.random()*0.8*Number.MAX_SAFE_INTEGER) + 0.1*Number.MAX_SAFE_INTEGER))
	popUpBG.style.height = docHeight + 'px';

	const popUp = document.createElement("div");
	popUp.classList.add('pop-up-modal');
	popUpBG.onclick = function (e) {
		if (e.target === popUpBG || e.target === popUp) {
			closeModalPopUp(popUpBG.id);
		}
	}

	const modalContent = document.createElement("div");
	modalContent.classList.add('modal-content');

	const modalHeader = document.createElement("div");
	modalHeader.classList.add('modal-header');

	const modalHeaderTitle = document.createElement("h5");
	modalHeaderTitle.classList.add('modal-title');
	modalHeaderTitle.appendChild( document.createTextNode(title))

	const modalHeaderClose = document.createElement("a");
	const modalheaderCloseButton = document.createElement("button");
	modalHeaderClose.href =`javascript:closeModalPopUp('${popUpBG.id}')`;
	modalheaderCloseButton.type = 'button'
	modalheaderCloseButton.classList.add('close')
	modalheaderCloseButton.innerHTML = '<span>&times;</span>'

	const modalBody = document.createElement("div");
	modalBody.classList.add('modal-body');
	modalBody.classList.add('pop-up-modal-body');

	const modalFooter = document.createElement("div");
	modalFooter.classList.add('modal-footer');

	const modalFooterClose = document.createElement("a");
	modalFooterClose.href =`javascript:closeModalPopUp('${popUpBG.id}')`;
	modalFooterClose.classList.add('btn', 'btn-secondary')
	modalFooterClose.appendChild( document.createTextNode('Close'))

	body.appendChild(popUpBG);
	popUpBG.appendChild(popUp);
	popUp.appendChild(modalContent);
	modalContent.appendChild(modalHeader);
	modalHeader.appendChild(modalHeaderTitle);
	modalHeader.appendChild(modalHeaderClose);
	modalHeaderClose.appendChild(modalheaderCloseButton)
	modalContent.appendChild(modalBody);
	modalBody.appendChild(textClone)
	modalContent.appendChild(modalFooter);
	modalFooter.appendChild(modalFooterClose);

	popUp.style.top = (window.scrollY + popUp.offsetTop) + 'px';
	body.style.overflow = 'hidden'

	return popUpBG.id;
}

closeModalPopUp = function (id) {
	let popUp = document.getElementById(id);

	popUp.remove();
	document.body.style.overflow = null
}